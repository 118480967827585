import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule,MatIconModule, MatButtonModule} from '@angular/material'
import { LightboxModule } from 'ngx-lightbox';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { commonApi } from './api/CommonApi';
import { TranslateApi } from './api/TranslateApi';
import { ImageApi } from './api/ImageApi';
import { VideoApi } from './api/VideoApi';
import { VoiceApi } from './api/VoiceApi';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { WordManagmentComponent } from './word_managment/wordmanagment.component';

import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import {FileUploadModule} from 'primeng/fileupload';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputNumberModule} from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';


import { WordService } from './_services/WordService';
import { ImpressumComponent } from './Impressum/Impressum.component';
import { DatenschutzComponent } from './Datenschutz/Datenschutz.component';
import { AboutusComponent } from './Aboutus/Aboutus.component';
import { ContactusComponent } from './ContactUs/contactus.component';
import { ContactUsApi } from './api/ContactUsApi';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavMenuComponent,
    HomeComponent,
    WordManagmentComponent,
    ImpressumComponent,
    DatenschutzComponent,
    AboutusComponent,
    ContactusComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,MatButtonModule,
    LightboxModule,
    AngularFontAwesomeModule,
    NgSelectModule,

   TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    HttpClientModule,
    FileUploadModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'WordManagment', component: WordManagmentComponent},
      { path: 'Impressum', component:ImpressumComponent},
      { path: 'Datenschutz', component:DatenschutzComponent},
      { path: 'Überuns', component:AboutusComponent},
      { path: 'kontaktformular', component:ContactusComponent},
      { path: 'login', component: LoginComponent },
      
    ]),
    BrowserAnimationsModule,
   
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    commonApi,
    TranslateApi,
    ImageApi,
    VideoApi,
    VoiceApi,
    ContactUsApi,
    WordService],
  bootstrap: [AppComponent]
})
export class AppModule { }
