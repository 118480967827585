import { Component,OnInit} from '@angular/core';

@Component({
  selector: 'app-Datenschutz',
  templateUrl: './Datenschutz.component.html',
  styleUrls: ['./Datenschutz.component.css'],
})
export class DatenschutzComponent implements OnInit 
{
ngOnInit()
 {
       
}

}
