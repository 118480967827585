import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component,Inject,OnInit, ViewChild} from '@angular/core';
import { ConfirmationService, FilterService, MessageService, PrimeNGConfig } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { commonApi } from '../api/CommonApi';

import { WordService } from '../_services/WordService';



@Component({
  selector: 'app-WordManagmentComponent',
  templateUrl: './wordmanagment.component.html',
  styleUrls: ['./wordmanagment.component.css'],
  providers: [ TableService,FilterService,MessageService,PrimeNGConfig,ConfirmationService],
})
export class WordManagmentComponent implements OnInit {
  baseUrl: string;
  words: any[]=[{id:'1'}];
  columns = [];
  uploadedFiles: any[] = [];
  languges:any[]=[];
  public progress: number;
  wordDialog: boolean;
  submitted: boolean;
  selectedword:any;
 constructor( @Inject('BASE_URL')  baseUrl: string,private _commonApi:commonApi, private confirmationService: ConfirmationService,private http: HttpClient,private messageService: MessageService,private wordService:WordService)
 {
   this.baseUrl=baseUrl;
 }
  

    ngOnInit() {
        this.wordService.getWords().subscribe(res => this.loadgrid(res));
    }

    private loadgrid(respons)
    {
      if(respons.succeeded==false)
      {
        return;
      }
      console.log(respons);
      this.columns=respons.data.cols;
      this.words=respons.data.words;

    }
    onBasicUploadAuto(event) {

      this.progress=0;
      let fileToUpload = <File>event.files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      console.log(this.baseUrl);
      this.http.post(this.baseUrl+"api/v1/Word/ImportExcelFile/ImportExcelFileAsync", formData, {reportProgress: true, observe: 'events'})
      .subscribe(myevent => {
        console.log("this");
        if (myevent.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * myevent.loaded / myevent.total);
        else if (myevent.type === HttpEventType.Response) {
          console.log(myevent);
          this.messageService.add({severity: 'success', summary: 'Erfolg', detail: 'Datei hochgeladen'});
        }
      });
      
  }

  deleteWord(word:any) {
   
    this.confirmationService.confirm({
        message: 'Sind Sie sicher, dass Sie löschen möchten ' + word.translates[1].value + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            var model= {
              Id:word.id
            };
            
            this.wordService.DeleteWord(model).subscribe(res=>{
              this.wordService.getWords().subscribe(res => this.loadgrid(res));
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Word Deleted', life: 3000});

            });

        }
    });
}

editWord(word:any) {
  this.selectedword=word;
  console.log(this.selectedword);
  this._commonApi.getLanguages().subscribe(res=>{
    this.languges=res.Data;
  })
  this.wordDialog = true;
}

hideDialog()
{
  this.wordDialog = false;
  this.submitted = false;
} 

}
