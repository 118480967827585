import { Component,OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactUsApi } from '../api/ContactUsApi';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactusComponent implements OnInit 
{
    contactForm = new FormGroup({
        FullName: new FormControl('',[Validators.required]),
        Email: new FormControl('',[Validators.email]),
        Subject: new FormControl('',[Validators.required]),
        Message: new FormControl('',[Validators.required]),
        MobileNumber: new FormControl(''),
        Telephone: new FormControl(''),
        SexId: new FormControl(''),
      });
IsSuccess=false;
IsFail=false;


get GetSexId() {
  return this.contactForm.get('SexId');
}

constructor(private _contactUsApi: ContactUsApi){

}

ngOnInit()
 {
       
}
onSubmit()
{
  console.log('this.GetSexId.value:');
  console.log(this.GetSexId.value);

  console.warn(this.contactForm.value);
  console.warn(this.contactForm.valid);
  if(this.contactForm.valid)
  {
  
    var model={
        SexId:this.GetSexId.value,
        FullName:this.contactForm.controls["FullName"].value,
        Email:this.contactForm.controls["Email"].value,
        MobileNumber:this.contactForm.controls["MobileNumber"].value,
        Subject :this.contactForm.controls["Subject"].value,
        Telephon:this.contactForm.controls["Telephone"].value,
        Message:this.contactForm.get("Message").value
    }
    console.warn(model);
    this._contactUsApi.AddContact(model)._subscribe((result:any)=>{
       
      if(result.succeeded==true)
      {
        this.IsSuccess=true;
        this.IsFail=false;
        this.resetform();
      } 
      else{
        this.IsSuccess=false;
        this.IsFail=true;
      } 
    });
 
  }
    else
    {
      this.IsSuccess=false;
      this.IsFail=true;
    }
    
}


resetform()
{
  this.contactForm.controls["FullName"].setValue('');
  this.contactForm.controls["Email"].setValue('');
  this.contactForm.controls["Subject"].setValue('');
  this.contactForm.controls["Message"].setValue('');
}
}