import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable()
export class WordService {
    private baseUrl: string;
    versionApi :string='v1';
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
    }
    constructor(@Inject('BASE_URL') baseUrl: string,private http: HttpClient)
     {
        this.baseUrl=baseUrl;
    }

    getWords() {
        return this.http.post<any>(this.baseUrl + 'api/'+this.versionApi+'/Word/GetWords/GetWords',{}, this.httpOptions)
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        );
    }
    DeleteWord(pram) {
     
      return this.http.post<any>(this.baseUrl + 'api/'+this.versionApi+'/Word/DeleteWord',pram, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      );
  }
    


    errorHandl(error) {
        let errorMessage = '';
        if(error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
      
        } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
      }
}