import { Component,OnInit} from '@angular/core';

@Component({
  selector: 'app-Impressum',
  templateUrl: './Impressum.component.html',
  styleUrls: ['./Impressum.component.css'],
})
export class ImpressumComponent implements OnInit 
{
ngOnInit()
 {
       
}

}
