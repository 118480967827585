import { Inject, Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { retry, catchError, delay } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';

@Injectable()
export class VoiceApi {
constructor(private http: HttpClient,@Inject('BASE_URL') baseUrl: string) {
    this.baseUrl=baseUrl;
}
baseUrl: string;
versionApi :string='v1';
httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'withCredentials': 'true',
    })
}

Get(param) :any
   {
    return this.http.post<any>(this.baseUrl + 'api/'+this.versionApi+'/Voice/Excute',param, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    );
}
 // Error handling
 errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
  
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}


