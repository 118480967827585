import { Component,OnInit} from '@angular/core';

@Component({
  selector: 'app-Aboutus',
  templateUrl: './Aboutus.component.html',
  styleUrls: ['./Aboutus.component.css'],
})
export class AboutusComponent implements OnInit 
{
ngOnInit()
 {
       
}

}
