import { Component,ElementRef,Inject,OnInit, ViewChild } from '@angular/core';
import { NgSelectConfig,ɵs} from '@ng-select/ng-select';
import { map } from 'rxjs/operators';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { commonApi } from '../api/CommonApi';
import { Observable, throwError, of } from 'rxjs';
import { TranslateApi } from '../api/TranslateApi';
import { ImageApi } from '../api/ImageApi';
import { VideoApi } from '../api/VideoApi';
import { VoiceApi } from '../api/VoiceApi';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  baseUrl:string;
  @ViewChild('txtareaword', { static: false }) txtareaword:ElementRef;
  @ViewChild('myAudio', { static: false }) myAudio: ElementRef<HTMLAudioElement>;
  _albums: Array<IAlbum> = [];
  _videos: Array<any> = [];
  _voices: Array<any> = [];
  selectedLanguageA: number;
  selectedLanguageB: number;
  languagesA = [];
  languagesB = [];
  translateApi:TranslateApi;
  imageApi:ImageApi;
  videoApi:VideoApi;
  voiceApi:VoiceApi;
  _voiceurl:string="https://localhost:44328//Sound/1.mp3";
  private wordId = 0;
  private wordCode = "";
  private word = '';
  private translate = '';
  private Example='';
  private category='';
  private Synonym='';
  
  constructor(private _lightbox: Lightbox,
    private config: NgSelectConfig
    ,_commonApi:commonApi
    ,_translateApi:TranslateApi
    ,_imageApi:ImageApi
    ,_videoApi:VideoApi
    ,_voiceApi:VoiceApi
    ,@Inject('BASE_URL') baseUrl: string) {
      this.baseUrl=baseUrl;
       this.config.notFoundText = 'Custom not found';
       this.config.appendTo = 'body';
       this.config.bindValue = 'value';
       this.translateApi=_translateApi;
       this.imageApi=_imageApi;
       this.videoApi=_videoApi;
       this.voiceApi=_voiceApi;
       _commonApi.getLanguages()._subscribe((result:any)=>{
        if(result.succeeded==true)
        {
          this.languagesA=result.data;
          this.languagesB=result.data;
        }  
      });
  }
  ngOnInit() {

  }
  selectChanged(evn)
  {
    this.doTextareaValueChange(null);
  }
  doTextareaValueChange(ev) {
    try {
      console.log(this.word);
     var model=
    {
       word: this.word,
       languageAId: this.selectedLanguageA,
       languageBId: this.selectedLanguageB
    };
   this.translate='';
      this.translateApi.Excute(model)._subscribe((result:any)=>{
       
        if(result.succeeded==true)
        {
          if(result.data!=null)
          {
           
            this.translate=result.data.value;
            this.wordId=result.data.wordId;
            this.wordCode=result.data.code;
            this.Example=result.data.example;
            this.category=result.data.category;
            this.Synonym=result.data.synonym;
            console.log('result.data');
            this.loadimages();
            this. loadvideos();
            this.loadvoice();
          }
          else{
            this._videos=[];
            this._voices=[];
            this._albums=[];
            this.wordId=0;
            this.wordCode="";
            this.Example="";
            this.category="";
            this.Synonym="";
            this.myAudio.nativeElement.src='';
          
          }
         
        } 
        else{
          this.translate=result.message
        } 
      });
     
    } catch(e) {
      console.info('could not set textarea-value');
    }
  }

  loadimages()
  { var model=
    {
      WordCode: this.wordCode
    };
    this._albums=[];
    this.imageApi.Get(model)._subscribe((result:any)=>{
     
      if(result.succeeded==true)
      {
        result.data.forEach((nos) => { // foreach statement  
          const src = this.baseUrl+"images/"+this.wordCode+'.jpg';
          const caption = "";
          const thumb = this.baseUrl+"images/"+this.wordCode+'.jpg';
          const album = {
            src: src,
            caption:"Bildquelle: "+ nos.source,
            thumb: thumb
         };
         this._albums.push(album);
      })  
        
      } 
     
    });
  }

  loadvideos()
  {
  
    
    var model=
    {
      WordCode: this.wordCode
    };
    this._videos=[];
    this.videoApi.Get(model)._subscribe((result:any)=>{
     
      if(result.succeeded==true)
      {
        result.data.forEach((nos) => { // foreach statement  
          const url = nos.url;
          const video = {
            url: url
         }; 
        this._videos.push(video);
      })  
        
      } 
     
    });
  }
  
loadvoice()
{
  this.myAudio.nativeElement.removeAttribute('src');
  this.myAudio.nativeElement.pause();
  this.myAudio.nativeElement.currentTime = 0;
  this.myAudio.nativeElement.src='';
  var model=
  {
    WordCode: this.wordCode
  };
  this._voices=[];
  this.voiceApi.Get(model)._subscribe((result:any)=>{
   
    if(result.succeeded==true)
    {
      result.data.forEach((nos) => { // foreach statement  
        const url = nos.url;
        this.myAudio.nativeElement.src=url;
        const voice = {
          url: url
       }; 
      this._voices.push(voice);
    })  
      
    } 
   
  });
}

open(index: number): void {
  // open lightbox
  this._lightbox.open(this._albums,index);
}

close(): void {
  // close lightbox programmatically
  this._lightbox.close();
}

play()
{
  this.myAudio.nativeElement.src=this.baseUrl+"/Sound/"+this.wordCode+".mp3";
  this.myAudio.nativeElement.load();
  this.myAudio.nativeElement.play();
 
}

}



const data = [
  {
    srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
    previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
    previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
    previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
  },
  {
    srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
    previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
  }
];